<template>
	<img :src="dataUrl" ref="imgfont" class="imgfont" />
</template>

<script>
// import JsBarcode from 'jsbarcode';

export default {
	// name: 'barcode',

	// props: ['code'],
	props: {
	  // mobile:"",
	  text: {
	    default: function() {
	      return '';
	    },
	    type: String
	  },
		options: {
			default: function() {
				return {
					width: 400,
					height: 30,
					fontSize: 25
				};
			},
			type: Object,
			requried: true,
		},
	},
	data() {
		return {
			canvas: "",
			dataUrl:""
		};
	},
	watch: {
		text: function(newVal, oldVal) {
			console.log('watchKey text',newVal);
			this.initData();
		}
	},
	created() {
		console.log('created');
		this.canvas = document.createElement('canvas');
		this.initData();
	},
	mounted() {
		console.log('monted');
	},
	methods: {
		initData() {
			// if (this.text) {
			this.drawText();
			// }
		},
		drawText(){
			// 创建canvas元素
			var canvas = this.canvas;
			var ctx = canvas.getContext('2d');
			// 设置画布大小
			canvas.width = this.options.width; // 设置宽度为500像素
			canvas.height = this.options.height; // 设置高度为300像素
			
			// 清空画布
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			
			// 绘制内容到画布上（这里只是一个示例）
			// ctx.fillStyle = 'red';
			// ctx.fillRect(10, 10, 480, 280);
			
			// ctx.font = this.options.fontSize+"px serif";
			ctx.font = this.options.fontSize+"px Arial,Helvetica,sans-serif";
			// ctx.textBaseline = "alphabetic";
			
			ctx.fillText(this.text, 0, this.options.height-5);
			 
			// 将画布转换为DataURL格式的图片数据
			this.dataUrl = canvas.toDataURL();
			// console.log(dataUrl);
		},
		// getBarCode(value) {
		// 	this.$nextTick(() => {
		// 		try {
		// 			let barcode_ref = this.$refs.barcode;
		// 			JsBarcode(barcode_ref, value, this.options);
		// 		} catch (e) {
		// 			console.log(e);
		// 			this.$message.warning(this.$t('i18nn_6b3c6618251b6db5'));
		// 		}
		// 	});
		// }
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>
