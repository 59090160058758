<template>
  <!-- <div> -->
    <el-cascader
      :value="value"
      :size="size"
      :options="options"
      :props="props"
      :disabled="disabled"
      :placeholder="placeholder"
      separator=" > "
      filterable
      :clearable="clearable"
      :change-on-select="changeOnSelect"
      @change="change"
      @remove-tag="removeTag"
      @clearCheckedNodes="clearCheckedNodes"
      :style="{width: width+'px'}">
    </el-cascader>

    <!-- <el-row :gutter="gutter_show">
      <el-col :span="spanSize" v-loading="allLinkageData.data_1_Loading">
        <el-select filterable clearable v-model="allLinkageData.data_1_Id" :size="size" :placeholder="plaprefix" @change="selChangeData_1($event)">
          <el-option v-for="item in selectOption.ListData_1" :key="item.id" :label="item.subjectName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="spanSize" v-loading="allLinkageData.data_2_Loading" v-if="!hideShowL2">
        <el-select filterable  clearable v-model="allLinkageData.data_2_Id" :size="size" :placeholder="plaprefix" @change="selChangeData_2($event)">
          <el-option v-for="item in selectOption.ListData_2" :key="item.id" :label="item.subjectName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8" v-loading="allLinkageData.data_3_Loading" v-if="!hideShowL3">
        <el-select filterable  clearable v-model="allLinkageData.data_3_Id" :size="size" :placeholder="plaprefix" @change="selChangeData_3($event)">
          <el-option v-for="item in selectOption.ListData_3" :key="item.id" :label="item.subjectName" :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row> -->
  <!-- </div> -->
</template>
<script>
import Vue from 'vue'

  // const getCascDtata = (scale,code) => {
  //   return Vue.prototype.$http
  //     .put(Vue.prototype.$urlConfig.HyCommodityCateList, {
  //       parentId: code,
  //       scale: scale
  //     });
  // }
export default {
  // name: 'HyCityCountyLinkage',

  // props: ['initLinkageData', 'sizeAll', 'gutter', 'placeholderprefix', 'hideShowL2', 'hideShowL3','value','changeOnSelect'],
  props: {
    // openDate:"",
    // initLinkageData:{
    //   default: function() {
    //     return {}
    //   },
    //   type: Object
    // },
    props:{
      default: function() {
        return {
          value: 'id',
          label: 'subjectName',
          children: 'nexts',
          expandTrigger: 'hover',
        }
      },
      type: Object
    },
    placeholder:{
      default: function() {
        return this.$t('i18nn_470bbdcc42b395ec')
      },
      type: String
    },
    width:{
      default: function() {
        return 400
      },
      type: Number
    },
    size:{
      default: function() {
        return 'small'
      },
      type: String
    },
    gutter:{
      default: function() {
        return ''
      },
      type: String
    },
    value:{
      default: function() {
        return []
      },
      type: Array
    },
    changeOnSelect:{
      default: function() {
        return false
      },
      type: Boolean
    },
    clearable:{
      default: function() {
        return true
      },
      type: Boolean
    },
    disabled:{
      default: function() {
        return false
      },
      type: Boolean
    },
    //可选的层级，下拉展示的层级
    showLevel:{
      default: function() {
        return 3
      },
      type: Number
    },
  },
  data() {
    return {
      // spanSize: 8,
      // size: '',
      // plaprefix: '',
      // gutter_show: 0,

      // value: [],
      options: [],
		loading:false,
      // props: {
      //   value: 'id',
      //   label: 'subjectName',
      //   children: 'nexts',
      //   expandTrigger: 'hover',
      // },

      // isHiddenCountyData: this.isHiddenCounty,
      // selectOption: {
      //   ListData_1: [],
      //   ListData_2: [],
      //   ListData_3: []
      //   // storeList: []
      // },
      // allLinkageData: {
      //   data_1_Loading: false,
      //   data_2_Loading: false,
      //   data_3_Loading: false,
      //   // storeLoading: false,
      //   data_1_Id: '',
      //   data_1_Name: '',
      //   data_2_Id: '',
      //   data_2_Name: '',
      //   data_3_Id: '',
      //   data_3_Name: ''
      //   // storeId: '',
      //   // storeName: '',
      // }
    };
  },
  // watch: {
  //   value: function(val1, val2) {
  //     console.log("watch cas value",arguments);
  //     // this.getListData_1();
  //     // this.init();
  //   }
  //   // initLinkageData: function(val1, val2) {
  //   //   // this.getListData_1();
  //   //   this.init();
  //   // }
  // },
  //创建时
  created() {
    // console.log(this.selectOption);

    this.init();
    //一级分类数据

    // let formData = {};
    //         let url = "";

    //         // if('cottonForm'===formName){
    //           formData = this.cottonForm;
    //           url = this.$urlConfig.SellerAddCotton;
    //         // }

    //         this.postData(url,formData);
    // this.getDataDictionary();
  },
  //编译挂载前
  mounted() {
    // console.log("mounted");
  },
  methods: {
    init() {
      // this.props = Object.assign(this.myProps, this.props)
      this.getListData_1();
      //选项框大小
      // if (this.sizeAll) {
      //   this.size = this.sizeAll;
      // }
      //布局大小
      // if (this.isHiddenCounty && !this.isShowStore) {
      //   this.spanSize = 12;
      // }
      //布局间隔
      // if (this.gutter) {
      //   this.gutter_show = this.gutter;
      // }
      //布局间隔
      // if (this.placeholderprefix) {
      //   this.plaprefix = this.placeholderprefix;
      // }

      // 初始赋值
      // if (this.initLinkageData) {

      //   this.allLinkageData.data_1_Id = this.initLinkageData.data_1_Id ? this.initLinkageData.data_1_Id : '';
      //   this.allLinkageData.data_1_Name = this.initLinkageData.data_1_Name ? this.initLinkageData.data_1_Name : '';
      //   this.allLinkageData.data_2_Id = this.initLinkageData.data_2_Id ? this.initLinkageData.data_2_Id : '';
      //   this.allLinkageData.data_2_Name = this.initLinkageData.data_2_Name ? this.initLinkageData.data_2_Name : '';
      //   this.allLinkageData.data_3_Id = this.initLinkageData.data_3_Id ? this.initLinkageData.data_3_Id : '';
      //   this.allLinkageData.data_3_Name = this.initLinkageData.data_3_Name ? this.initLinkageData.data_3_Name : '';

      // }
    },

    //值改变
    change(v){
      console.log(v);
      this.$emit('change', v);
    },
    removeTag(v){
      console.log("removeTag",v);
      this.$emit('removeTag', v);
    },
    clearCheckedNodes(v){
      console.log("clearCheckedNodes",v);
      this.$emit('clearCheckedNodes', v);
    },

    //选择一级分类
    // selChangeData_1(vId) {
    //   console.log('selChangeData_1', vId);

    //   // console.log(this.allLinkageData.data_1_Id);
    //   // console.log(this.allLinkageData.data_1_Name);

    //   this.allLinkageData['data_2_Id'] = '';
    //   this.allLinkageData['data_2_Name'] = '';
    //   this.allLinkageData['data_3_Id'] = '';
    //   this.allLinkageData['data_3_Name'] = '';

    //   // this.allLinkageData['storeId'] = "";
    //   // this.allLinkageData['storeName'] = "";

    //   //清空
    //   // this.selectOption.ListData_2 = [];
    //   //清空
    //   // this.selectOption.ListData_3 = [];
    //   //清空
    //   // this.selectOption.storeList = [];
    //   if (!vId) {
    //     //清空操作
    //     this.allLinkageData.data_1_Id = '';
    //     this.allLinkageData.data_1_Name = '';
    //     this.$emit('selChangeData_1', this.allLinkageData);
    //     return;
    //   }

    //   if (!this.hideShowL2) {
    //     this.getListData_2(vId);
    //   }

    //   // let list = this.selectOption.ListData_1;

    //   for (let i = list.length - 1; i >= 0; i--) {
    //     // list[i]
    //     if (list[i].id === vId) {
    //       // return list[i];
    //       this.allLinkageData.data_1_Name = list[i].subjectName;
    //       //触发父组件方法
    //       this.$emit('selChangeData_1', this.allLinkageData);
    //       break;
    //     }
    //   }
    // },

    //选择二级分类
    // selChangeData_2(vId) {
    //   console.log('selChangeData_2', vId);
    //   this.allLinkageData['data_3_Id'] = '';
    //   this.allLinkageData['data_3_Name'] = '';

    //   // this.allLinkageData['storeId'] = "";
    //   // this.allLinkageData['storeName'] = "";
    //   //清空区县
    //   // this.selectOption.ListData_3 = [];
    //   //清空仓库
    //   // this.selectOption.storeList = [];

    //   if (!vId) {
    //     //清空操作
    //     this.allLinkageData.data_2_Id = '';
    //     this.allLinkageData.data_2_Name = '';
    //     this.$emit('selChangeData_2', this.allLinkageData);
    //     return;
    //   }

    //   // if (!this.isHiddenCounty) { //显示区县
    //   if (!this.hideShowL3) {
    //     this.getListData_3(vId);
    //   }
    //   // }
    //   // if (this.isShowStore) { //显示仓库
    //   //   this.getCityStoryData(vId);
    //   // }

    //   let list = this.selectOption.ListData_2;
    //   for (let i = list.length - 1; i >= 0; i--) {
    //     // list[i]
    //     if (list[i].id === vId) {
    //       // return list[i];
    //       // this[formName][formKeyName] = list[i].name;
    //       this.allLinkageData.data_2_Name = list[i].subjectName;
    //       //触发父组件方法
    //       this.$emit('selChangeData_2', this.allLinkageData);
    //       console.log(this.allLinkageData);
    //       break;
    //     }
    //   }
    // },
    //选择
    // selChangeData_3(vId) {
    //   if (!vId) {
    //     //清空操作
    //     this.allLinkageData.data_3_Id = '';
    //     this.allLinkageData.data_3_Name = '';
    //     this.$emit('selChangeData_3', this.allLinkageData);
    //     return;
    //   }
    //   // this[formName][formKeyName] = obj.subValue;
    //   let list = this.selectOption.ListData_3;
    //   // console.log(vId,list);

    //   for (let i = list.length - 1; i >= 0; i--) {
    //     // list[i]
    //     if (list[i].id === vId) {
    //       // this[formName][formKeyName] = list[i].storeName;
    //       this.allLinkageData.data_3_Name = list[i].subjectName;
    //       //触发父组件方法
    //       this.$emit('selChangeData_3', this.allLinkageData);
    //       console.log(this.allLinkageData);
    //       break;
    //       // return list[i];
    //     }
    //   }
    // },

    //一级分类信息
    getListData_1() {
      // let _this = this;
      // this.allLinkageData.data_1_Loading = true;
      this.loading = true;
      this.$http
        .put(this.$urlConfig.HyCommodityCateTreeList, {
          // parentId: null,
          // scale: 1
        })
        .then(({ data }) => {
          // console.log(this.$t('i18nn_3401b0539851b242'));
          console.log(data);
          this.loading = false;
          if (200 == data.code) {
            // this.selectOption['ListData_1'] = data.rows;
            // let options = data.rows;
            // options.forEach(item => {
            //   item.children = [];
            // });

            let options = data.rows;
            if(1===this.showLevel){
              options.forEach(item => {
                item.nexts = null;
              });
            } else if(2===this.showLevel){
              options.forEach(item => {
                item.nexts.forEach(item2 => {
                  item2.nexts = null;
                });
              });
            }

            this.options = options;
          } else {
            this.$message({
              message: '商品类目请求失败!',
              type: 'warning'
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
          console.log(this.$t('i18nn_758239e08104dfb8'));
          this.$message({
            message: this.$t('i18nn_758239e08104dfb8'),
            type: 'warning'
          });
        });
      // }
    },
    //二级分类信息
    // getListData_2(code) {
    //   if (!code) {
    //     console.log(this.$t('i18nn_c08547dbd9309a2e'));
    //     return;
    //   }
    //   // let _this = this;
    //   this.allLinkageData.data_2_Loading = true;
    //   // this.selectOption['storeList'] = [];
    //   this.$http
    //     .put(this.$urlConfig.HyCommodityCateList, {
    //       parentId: code,
    //       scale: 2
    //     })
    //     .then(({ data }) => {
    //       console.log(this.$t('i18nn_36fc2cb9f1efdfd9'), code);
    //       this.allLinkageData.data_2_Loading = false;
    //       console.log(data);
    //       if (200 == data.code) {
    //         // this.selectOption['ListData_2'] = data.rows;
    //       } else {
    //         this.$message({
    //           message: this.$t('i18nn_37cfdcf4c24cce7a'),
    //           type: 'warning'
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       console.log(this.$t('i18nn_50651d1991159001'));
    //       this.allLinkageData.data_2_Loading = false;
    //       this.$message({
    //         message: '二级类目请求有误!',
    //         type: 'warning'
    //       });
    //     });
    // },
    //三级类目
    // getListData_3(code) {
    //   if (!code) {
    //     console.log(this.$t('i18nn_c08547dbd9309a2e'));
    //     return;
    //   }
    //   // let _this = this;/**/
    //   // this.selectOption['storeList'] = [];
    //   this.allLinkageData.data_3_Loading = true;
    //   this.$http
    //     .put(this.$urlConfig.HyCommodityCateList, {
    //       parentId: code,
    //       scale: 3
    //     })
    //     .then(({ data }) => {
    //       console.log(this.$t('i18nn_b1f5481a8729a352'), code);
    //       this.allLinkageData.data_3_Loading = false;
    //       console.log(data);
    //       if (200 == data.code) {
    //         // this.selectOption['ListData_3'] = data.rows;
    //       } else {
    //         this.$message({
    //           message: this.$t('i18nn_3a7d834707ea72d3'),
    //           type: 'warning'
    //         });
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.allLinkageData.data_3_Loading = false;
    //       console.log(this.$t('i18nn_3a7d834707ea72d3'));
    //       this.$message({
    //         message: '三级类目请求失败！',
    //         type: 'warning'
    //       });
    //     });
    // }

  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
